<template>
<b-card no-body>
  <validation-observer
    v-slot="{ handleSubmit }" ref="VForm"
  >
    <b-form @submit.prevent="handleSubmit($parent.doSubmitCRUD('VForm', $refs))">
      <b-card-header>
        <b-row>
          <b-col lg="8">
            <h5 class="card-title">{{ $parent.isAdd?'Tambah':'Edit' }} {{ $parent.pageTitle }}</h5>
          </b-col>
        </b-row>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col lg="8">
            <b-row>
              <b-col lg="6">
                <b-form-group>
                  <label>Kode<span class="text-danger mr5">*</span></label>
                  <b-form-input type="text" class="form-control" v-model="row.mi9u_code" placeholder="e.g 0001" :formatter="$parent.code" />
                  <VValidate 
                    name="Kode" 
                    v-model="row.mi9u_code" 
                    :rules="toValidate(mrValidation.mi9u_code)"
                  />
                </b-form-group>
              </b-col>

              <b-col lg="6">
                <b-form-group>
                  <label>Nama<span class="text-danger mr5">*</span></label>
                  <b-form-input :formatter="$parent.normalText" placeholder="Nama" type="text" class="form-control" v-model="row.mi9u_name"/>
                  <VValidate 
                    name="Nama" 
                    v-model="row.mi9u_name" 
                    :rules="toValidate(mrValidation.mi9u_name)"
                  />
                </b-form-group>
              </b-col>
              
              <!--
              <b-col lg="6">
                <b-form-group class="mt-3">
                  <label>Status Penyakit<span class="text-danger mr5">*</span></label>
                  <b-form-radio-group
                    :options="Config.mr.StatusMenular"
                    v-model="row.mi9u_is_menular"
                  />
                </b-form-group>
              </b-col>
              -->

              <b-col lg="6">
                <b-form-group class="mt-3">
                  <label>Status <span class="text-danger mr5">*</span></label>
                  <b-form-radio-group
                    :options="Config.mr.StatusOptions"
                    v-model="row[$parent.statusKey]"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
          <!--
          <b-col lg="4">
             <b-row>
                <b-col lg="12">
                <label>Informed Consent <span class="text-danger mr5">*</span></label><br/>
                <a href="javascript:;" @click="isOpenInformedConsent = true" class="btn btn-sm btn-icon alpha-indigo text-indigo-800 border-indigo" title="Tambah Informed Consent" v-b-tooltip.hover.right>
                  <i class="icon-file-check2"></i>
                </a>

                <a href="javascript:;" v-if="row.mi9u_informed_consent" @click="doDeleteIC" class="btn btn-sm btn-icon alpha-danger text-danger-800 border-danger ml-2" title="Hapus Informed Consent" v-b-tooltip.hover.right>
                  <i class="icon-bin"></i>
                </a>
                </b-col>
             </b-row>
          </b-col>
          -->
          
        </b-row>
      </b-card-body>
      <b-card-footer>
        <b-row>
          <b-col lg="12" class="text-right">
            <button @click="$router.back()" type="button" class="btn  btn-rounded  btn-default mr-3">Batal</button>
            <b-button type="submit" variant="primary" class="btn-rounded">Simpan</b-button>
          </b-col>
        </b-row>
      </b-card-footer>
    </b-form>
  </validation-observer>

  
  <validation-observer ref="VFormInformconsent">
    <b-modal v-model="isOpenInformedConsent" :title="'Tambahkan Informed Consent'" size="lg" @ok.prevent="submitInformConcent">
      <table class="table table-bordered table-sm table-input">
        <thead>
          <tr>
            <td colspan="3">
              <div class="result_tab">
                <h4>Nama Tindakan / ICD-9-CM</h4>
                <p>{{row.mi9u_name}} <span class="font-weight-semibold">[{{row.mi9u_code}}]</span></p>
              </div>
            </td>
          </tr>
          <tr>
            <th width="24">No.</th>
            <th>Jenis Informasi</th>
            <th>Isi Informasi</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>Diagnosis<br/>(Diagnosis Kerja & Diagnosis Banding)</td>
            <td class="input-cell">
              <textarea v-model="row.auicm_diagnosis" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
            </td>
          </tr>
          <tr>
            <td>2</td>
            <td>Dasar Diagnosis</td>
            <td class="input-cell">
              <textarea v-model="row.auicm_dasar_diagnosis" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
            </td>
          </tr>
          <tr>
            <td>3</td>
            <td>Tindakan Kedokteran</td>
            <td class="input-cell">
              <textarea v-model="row.auicm_tindakan_dokter" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
            </td>
          </tr>
          <tr>
            <td>4</td>
            <td>Indikasi Tindakan</td>
            <td class="input-cell">
              <textarea v-model="row.auicm_indikasi_tindakan" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
            </td>
          </tr>
          <tr>
            <td>5</td>
            <td>Tata Cara</td>
            <td class="input-cell">
              <textarea v-model="row.auicm_tata_cara" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
            </td>
          </tr>
          <tr>
            <td>6</td>
            <td>Tujuan</td>
            <td class="input-cell">
              <textarea v-model="row.auicm_tujuan" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
            </td>
          </tr>
          <tr>
            <td>7</td>
            <td>Risiko</td>
            <td class="input-cell">
              <textarea v-model="row.auicm_risiko" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
            </td>
          </tr>
          <tr>
            <td>8</td>
            <td>Komplikasi</td>
            <td class="input-cell">
              <textarea v-model="row.auicm_kompilasi" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
            </td>
          </tr>
          <tr>
            <td>9</td>
            <td>Prognosis</td>
            <td class="input-cell">
              <textarea v-model="row.auicm_prognosis" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
            </td>
          </tr>
          <tr>
            <td>10</td>
            <td>Alternatif & Risiko</td>
            <td class="input-cell">
              <textarea v-model="row.auicm_alternatif" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
            </td>
          </tr>
          <tr>
            <td>11</td>
            <td>Lain-lain</td>
            <td class="input-cell">
              <textarea v-model="row.auicm_lainnya" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
            </td>
          </tr>
        </tbody>
      </table>
    </b-modal>
  </validation-observer>

</b-card>
</template>

<script>
import $ from 'jquery'
const _ = global._
import Gen from '@/libs/Gen.js'

export default{
  props:{
    row:Object,
    mrValidation:Object,
    Config:Object
  },
  data() {
    return {
      isOpenInformedConsent : false
    }
  },
  methods: {
    toValidate(val){
      return {...val}
    },
    doDeleteIC(){
      this.$parent.$swal({
        title: 'Hapus Data Inform Consent ? ',
        text: "Anda tidak akan dapat memulihkan data ini!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Ya, Hapus!',
        cancelButtonText: 'Kembali',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$parent.loadingOverlay = true
          Gen.apiRest(
              "/do/"+this.$parent.modulePage,
              {data:{type:'delete-data-ic', id: this.row.mi9u_informed_consent, id_icd9: this.row.mi9u_id}}
          ).then(res=>{
            this.$parent.loadingOverlay = false
            this.$parent.apiGet()
          })
        }
      })
    },
    
    submitInformConcent(){
      this.$refs['VFormInformconsent'].validate().then(success=>{
        if(success){
           this.isOpenInformedConsent = false
           this.row.created_ic = true
        }
      })
    }
  },
}
</script>